/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PgStatusType, PgResponse } from '../../types/types';
import api from '../../utils/api';

import './style.scss';

function PgPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [PgData, setPgData] = useState<PgStatusType>();
  const [licencePlate, setLicencePlate] = useState<string>('');

  const [loadingSending, setLoadingSending] = useState<boolean>(false);
  const [displaySendingResult, setDisplaySendingResult] =
    useState<boolean>(false);

  const [displaySendingResultError, setDisplaySendingResultError] =
    useState<boolean>(false);

  const [sendingResponse, setSendingResponse] = useState<PgResponse>();

  const { id, aid } = useParams();

  useEffect(() => {
    if (id && aid) fetchRequestStatus(id, aid);
  }, []);

  const fetchRequestStatus = async (id: string, aid: string) => {
    const data = {
      id: id,
      aid: aid.toUpperCase(),
    };
    const result = await api<PgStatusType, any>(`/pg/status`, 'POST', data);
    if (!result) {
      setError(true);
    } else {
      if (result.error) {
        setError(true);
      } else {
        setPgData(result);
      }
      setLoading(false);
    }
  };

  const updateRequest = async (licencePlate: string) => {
    setLoadingSending(true);
    const data = {
      id: id,
      aid: aid,
      licence_plate: licencePlate,
    };
    const result = await api<PgResponse, any>(`/pg/new`, 'POST', data);
    if (!result) {
      setLoadingSending(false);
      setDisplaySendingResultError(true);
    } else {
      setLoadingSending(false);
      if (result.error) {
        setDisplaySendingResultError(true);
        return;
      }
      setDisplaySendingResult(true);
      setSendingResponse(result);
      setLicencePlate('');
      setTimeout(() => {
        setDisplaySendingResult(false);
      }, 12000);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Grid container marginTop={4} justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : error ? (
        <Grid container marginTop={2} justifyContent="center">
          <Grid item sx={{ maxWidth: 320 }}>
            <Alert severity="error">Denne lenken er ikke gyldig.</Alert>
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid container marginTop={2} justifyContent="center">
            <Grid item sx={{ maxWidth: 420, pl: 2, pr: 2 }}>
              <Paper sx={{ p: 3 }}>
                {PgData?.title && (
                  <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
                    {PgData?.title}
                  </Typography>
                )}
                {PgData?.description && (
                  <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                    {PgData?.description}
                  </Typography>
                )}
                <Divider />
                <Box sx={{ flexGrow: 1, height: '100%', mt: 2 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{ m: 0, minWidth: 320, maxWidth: 320 }}>
                      <Alert severity="info" sx={{ mb: 2 }}>
                        Oppgi registreringsnummeret til kjøretøyet som skal
                        motta parkeringstillatelse.
                      </Alert>
                      <TextField
                        id="license-plate"
                        label="Registreringsnummer"
                        value={licencePlate}
                        onChange={e => {
                          setLicencePlate(
                            e.target.value
                              .replace(/[^a-z0-9]/gi, '')
                              .toUpperCase(),
                          );
                        }}
                        sx={{ mb: 2 }}
                      />
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '8px',
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!licencePlate}
                      sx={{
                        pl: 4,
                        pr: 4,
                        backgroundColor: '#84216B',
                        '&:hover': {
                          backgroundColor: '#470A59',
                        },
                      }}
                      onClick={() => updateRequest(licencePlate)}
                    >
                      Gi tillatelse
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Dialog
        open={displaySendingResultError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">En feil har oppstått.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parkeringstilatelse er ikke registrert. Prøv på nytt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDisplaySendingResultError(false);
              setLoadingSending(false);
            }}
          >
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={displaySendingResult}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Parkeringstilatelse ble registrert.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Parkeringstilatelse for <b>{sendingResponse?.licence_plate}</b> er
            registrert.
            <br />
            <br />
            Parkeringstilatelsen er gyldig i{' '}
            <b>{sendingResponse?.duration} minutter</b> fra kjøretøyet
            kjører/kjørte inn på parkeringsområdet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDisplaySendingResult(false);
              setLoadingSending(false);
            }}
          >
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff' }} open={loadingSending}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default PgPage;
